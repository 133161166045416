export const data = [

    {
        productname: "cannabis kush",
        price: 400,
        total: 1,
        _id: 1,
        product_category: ["drugs", "Oils & Tinctures","Vapes & Carts"],
        decription:"saliva three four main cannabis"

    },
    {
        productname: "crack downfa%s ",
        price: 340,
        total: 1,
        _id: 2,
        product_category: ["drugs", "cannabis","Vapes & Carts"],
        decription:"saliva three four main cannabis"
        

    },
    {
        productname: "cockers buld ",
        price: 140,
        total: 1,
        _id: 3,
        product_category: ["drugs", "cannabis",],
        decription:"saliva three four main cannabis"
        

    },
    {
        productname: "florest forest",
        price: 340,
        total: 1,
        _id: 4,
        product_category: ["drugs", "cannabis","Vapes & Carts"],
        decription:"saliva three four main cannabis"

    },
    {
        productname: "crack downfa%s ",
        price: 340,
        total: 1,
        _id: 1,
        product_category: ["drugs", "cannabis",],
        decription:"saliva three four main cannabis"

    },

]